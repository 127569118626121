import { render } from '@testing-library/react';
import React, { useState, useEffect } from 'react';
import {data} from '../data';
import Converter from 'number-to-words';
import ConverterChinese from 'to-chinese-numeral';
import cjkConv from 'cjk-conv';


//KOBAYASHI ISSA
// let haiku = [
//     "All the time I pray to Buddha I keep on killing mosquitoes .",
//     "Even with insects— some can sing, some can’t .",
//     "Goes out , comes back — the love life of a cat .",
//     "Mosquito at my ear — does he think I’m deaf ? ",
//     "On a branch floating downriver a cricket , singing . ",
//     "The snow is melting and the village is flooded with children .",
//     "Under the evening moon the snail is stripped to the waist."
// ]

const PoemLayout = () => {
    const [poems, setPoem] = useState(data);
    const [status, setStatus] = React.useState("begin");
    const [seconds, setSeconds] = useState(0);
    const [time, setTime] = useState(0)
    const [changeTitle, setChangeTitle] = useState(false);
    const [counter, setCounter] = useState("FIND SOME");
    const [hanCounter, setHanCounter] = useState("几");
    const [selected, setSelected] = useState(null);
    const [unselected, setUnselected] = useState(null);
    const [isToggle, setToggle] = useState(false);
    const [timeOuts, setTimeOuts] = useState([null, null, null, null, null, null, null])
    // const [timeOuts, setTimeOuts] = useState(0)
    

    // let poemLength = poems.map((poem) => {
    //     const {id, title, content} = poem;
    //     const output = content.split(' ');
    //     return output.length;
    // })

    useEffect(()=>{
        // let textSelector = document.querySelector("#titleText");
        // let boxSelector = document.querySelector("#websiteTitle");
        // let fillSelector = document.querySelector("#fillText");
        // console.log(textSelector.clientWidth, boxSelector.clientWidth);
        // for(let i = 0; i < 10; i++){
        //     let prependText = "_";
        //     if(textSelector.clientWidth < boxSelector.clientWidth - 120){
        //         prependText = prependText + "_";
        //         textSelector.innerHTML += prependText;
        //     }
        // }
    });

    let currentPoem;
    let display;

    // let poems_split = [];
    // // let timeouts = [null, null, null, null, null, null, null];
    // let curr_index = [];

    // function cyclePoem(poemContent, textElement){
    //       let i = 0;
    //       console.log(poemContent.length, i)
    //       if(i % poemContent.length == 0){
    //         i = 0;
    //       }
    //       let word = poemContent[i];
    //     //   textElement.textContent = word;
    //       textElement.appendChild = "<span class='poemText'>" + word + "</span>"
    //       i++;
    // }
    let clicked = 0;
    const displayPoem = (id, content, index) => {
        currentPoem = poems.filter((poem) => poem.id === id);
        // let currentID = id;
        // if (currentID != previousID){
        //     previousID = currentID;
        // } else {
        //     setToggle(!isToggle);
        //     if(!isToggle){
        //         setStatus("working");
        //         setSelected(null);
        //         setSelected(id);
        //     } else {
        //         setStatus("stop");
        //         setTime(seconds);
        //         setUnselected(null);
        //         setUnselected(id);
        //     }
        // }
        // console.log(previousID, currentID, isToggle)
        // setStatus("working");
        // setSelected(id);
        let currentPoemID = `poem${id}`
        let currentTitleID = `title${id}`;
        let currentBoxID = `box${id}`;
        let currentPoemBlock = document.getElementById(currentPoemID);
        let currentTitleBlock = document.getElementById(currentTitleID);
        let currentBoxBlock = document.getElementById(currentBoxID);
        currentPoemBlock.classList.remove("start");
        if(currentPoemBlock.classList.contains("on")){
            setStatus("stop");
            setTime(seconds);
            currentPoemBlock.classList.add("off");
            currentPoemBlock.classList.remove("on");
            currentBoxBlock.classList.add("off");
            currentBoxBlock.classList.remove("on");
            clearInterval(poems[id-1].interval);
            currentTitleBlock.style.visibility = 'hidden';
            // let idIntervals = [...poems];
            // let idInterval = {...idIntervals[id]}
            // idInterval = interval
            // idIntervals[id-1].interval = idInterval;
            // stopText();
            // return (
            //     <React.Fragment>
            //         display = <div>{content[time]}</div>
            //     </React.Fragment>
            // )
        } else {
            // setStatus("stop");
            // setTime(seconds);
            // console.log(time);
            // setUnselected(null);
            // setUnselected(id);
            setStatus("working");
            setSelected(null);
            setSelected(id);
            clicked++;
            currentPoemBlock.classList.add("on");
            currentPoemBlock.classList.remove("off");
            currentBoxBlock.classList.add("on");
            currentBoxBlock.classList.remove("off");
            currentTitleBlock.style.visibility = 'visible';
            // currentPoem.innerHTML = `${content[seconds]}`
            // currentPoemBlock.innerHTML= `${content[seconds]}`;
            // let displayPoem = cycle();
            cycle(id, content, currentPoemBlock);
            // return (
            //     <React.Fragment>
            //         display = <div>{content[seconds]}</div>
            //     </React.Fragment>
            // )
        }
        allOff();
        // console.log(currentPoemBlock);
    }

    const allOff = () => {
        const poemBoxselector = document.querySelectorAll(".poem");
        const poemBoxOffselector = document.querySelectorAll(".poem.off");
        const poemBoxOnselector = document.querySelectorAll(".poem.on");
        const placeholderSelector = document.querySelectorAll(".placeHolder");
        const numberToWord = Converter.toWords(poemBoxOnselector.length);
        const numberToHan = ConverterChinese(poemBoxOnselector.length);
        setCounter(numberToWord.toUpperCase());
        setHanCounter(numberToHan);
        if(poemBoxselector.length === poemBoxOffselector.length){
            setChangeTitle(true);
            // placeholderSelector.forEach((el)=>{
            //     el.classList.add("on")
            // })
        } else {
            setChangeTitle(false);
            // placeholderSelector.forEach((el)=>{
            //     el.classList.remove("on")
            // })
        }
        if(poemBoxselector.length === poemBoxOnselector.length){
            placeholderSelector.forEach((el)=>{
                el.classList.add("on")
            })
            document.body.style.background = "#f2f2f2";
        } else {
            if(poemBoxselector.length === poemBoxOffselector.length){
                placeholderSelector.forEach((el)=>{
                    el.classList.remove("on")
                })
                document.body.style.background = "#262626";
            }
            // placeholderSelector.forEach((el)=>{
            //     el.classList.remove("on")
            // })
            // document.body.style.background = "#262626";
        }
    }

    // allOn();

    function cycle(id, poem, textElement){
        let interval;
        // if(i > poem.length){
        //     i = 0;
        // }
        let currentText;
        if (poems[id-1].stop > 0){
            currentText = poems[id-1].stop;
        } else {
            currentText = 0;
        }
        // let targetElm = textElement.getElementsByClassName("poemText")[0];
        // targetElm.classList.remove("start");
        // targetElm.textContent = poem[currentText];

        // textSpan.innerHTML = poem[i]
        // textElement.appendChild(textSpan)
        // i++;
        // var RiTaData = RiTa.analyze(poem[currentText]);
        // var syllables_arr = RiTaData.syllables.toString().split("/");
        // var displayInterval = syllables_arr * 250;
        interval = setInterval(() => {
            if (currentText < poem.length) {
                textElement.textContent = poem[currentText]
                currentText++;
                let stops = [...poems];
                let stop = {...stops[id]}
                stop = currentText;
                stops[id-1].stop = stop;
            } else {
                // clearInterval(interval);
                currentText = 0;
            }
        }, 1200); 

        let idIntervals = [...poems];
        let idInterval = {...idIntervals[id]}
        idInterval = interval
        idIntervals[id-1].interval = idInterval; 
    }

    return(
        <React.Fragment>
            <div className="container">
                <div className="placeHolder" id="websiteTitle">
                    <span id="fillText"></span>
                    <span id="titleText">{!changeTitle ? `${counter} HAIKU` : "ONE EXTRA POEM"}</span>
                </div>
                {/* <div className="placeHolder" id="websiteTitle">THIRTEEN HAIKU</div> */}
                {/* <div className="placeHolder" id="poet">BY KOBAYASHI ISSA</div> */}
                <div className="placeHolder" id="poet">{!changeTitle ? "BY KOBAYASHI ISSA" : "YOURNAME_HERE"}</div>
                <div className="placeHolder" id="control">#2</div>
                <div className="placeHolder" id="time"></div>
                <div className="placeHolder" id="extra1">{!changeTitle ? "作者 _ ( 小 林 一 茶 )" : "作者 _ 写下你的名字"}</div>
                <div className="placeHolder" id="extra2">{!changeTitle ? `俳 句 ${hanCounter}则` : "随机诗歌一则"}</div>
            {poems.map((poem, index)=>{
                const {id, title, content, start, stop} = poem;
                const output = content.split(' ');
                const isSelected = selected === id;
                const isUnSelected = unselected === id;
                let idArr = [1,2,3,4,5,6,7];
                // let currentPoemBlock = document.getElementById(selected);
                // console.log(currentPoemBlock)
                // if (currentPoemBlock !=null) {
                //     if(isSelected && currentPoemBlock.classList.contains("on")){
                //         display = <div>{output[seconds]}</div>
                //     }
                //     if(isSelected && currentPoemBlock.classList.contains("off")){
                //         display = <div>{output[time]}</div>
                //     }
                    
                // } else {
                //     display = <div>{output}</div>
                // }
                // if (isSelected) {
                //     display = <div>{output[seconds]}</div>
                //     // idArr.splice(idArr.indexOf(id), 1);
                //     // console.log(idArr);
                // } else if (isUnSelected){
                //     display = <div>{output[time]}</div>
                // } else {
                //     display = <div>{output[0]}</div>
                // }
                let poemBlockid = `poem${id}`;
                let poemBoxid = `box${id}`;
                let poemTitleid = `title${id}`;
                return(
                    <>
                        <div className="item" key={index} id={poemBoxid}>
                        <div className="title" id={poemTitleid}>{title}</div>
                        {/* <button onClick={()=>{stopPoem(id)}}>stop</button> */}
                        <div id={poemBlockid} className="poem start" onClick={()=>{displayPoem(id, output, index)}}>
                            {/* <span className="poemText start"></span> */}
                            {/* {isSelected ? (
                                <div id={id}>{display}</div>
                                ) :  (<div>{output[time]}</div>)} */}
                        </div>
                        </div>
                    </>
                )

            })}
            </div>
        </React.Fragment>
    )
}

export default PoemLayout;