

export const _table_tw = {
	"罗": "羅",
	"恶": "惡",
	"苏": "蘇",
	"馆": "館"
} as const;

/**
 * 此表內只有符合 KEY 值時才會觸發
 */
export const table_jp_core = {
	"の": [
		"の",
		"之",
		"的"
	],
	"と": [
		"と",
		"與",
		"与"
	],
	"画": [
		"划",
		"画",
		"劃",
		"畫"
	],
	"闘": [
		"斗"
	],
	"鬥": [
		"斗"
	],
	"鬭": [
		"斗"
	],
	"鬪": [
		"斗"
	],
	"闇": [
		"暗"
	],
	"図": [
		"図",
		"圖",
		"图"
	],
	"当": [
		"当",
		"噹",
		"當",
		"儅"
	],
	"閤": [
		"合"
	],
	"阁": [
		"合"
	],
	"罗": [
		"羅",
		"罗",
		"儸",
		"㑩",
		"囉",
		"啰"
	],
	"干": [
		"幹",
		"乾",
		"干"
	],
	"幹": [
		"幹",
		"干"
	],
	"乾": [
		"干",
		"乾",
		"亁",
		"乹"
	],
	"亁": [
		"乾",
		"亁",
		"乹"
	],
	"乹": [
		"乾",
		"亁",
		"乹"
	],
	"历": [
		"历",
		"歴",
		"歷",
		"暦",
		"曆"
	],
	"歴": [
		"历"
	],
	"歷": [
		"历"
	],
	"暦": [
		"历"
	],
	"曆": [
		"历"
	],
	"呻": [
		"呻",
		"申"
	],
	"覆": [
		"覆",
		"复"
	],
	"复": [
		"覆"
	],
	"勉": [
		"免"
	],
	"勉": [
		"免"
	],
	"甦": [
		"甦",
		"蘇",
		"苏"
	],
	"忧": [
		"憂",
		"優"
	],
	"鹹": [
		"咸",
		"鹹"
	],
	"准": [
		"準",
		"准"
	],
	"準": [
		"準",
		"准"
	],
	"袮": [
		"袮",
		"祢"
	],
	"儘": [
		"儘",
		"侭"
	],
	"侭": [
		"儘",
		"侭"
	],
	"脏": [
		"脏",
		"髒",
		"臟"
	],
	"发": [
		"髮",
		"髪",
		"发"
	],
	"髮": [
		"發",
		"发"
	],
	"慾": [
		"慾",
		"欲"
	],
	"讚": [
		"讃",
		"讚",
		"贊",
		"赞",
		"賛"
	],
	"冲": [
		"冲",
		"沖",
		"衝"
	],
	"面": [
		"麵",
		"麪",
		"麺"
	],
	"鬚": [
		"鬚",
		"須",
		"须"
	],
	"揹": [
		"揹",
		"背"
	],
	"捲": [
		"捲",
		"卷",
		"巻"
	],
	"卷": [
		"捲",
		"卷",
		"巻",
		"劵",
		"券"
	],
	"巻": [
		"捲",
		"卷",
		"巻",
		"劵",
		"券"
	],
	"苏": [
		"苏",
		"蘇",
		"甦"
	],
	"瀏": [
		"瀏",
		"浏",
		"刘"
	],
	"浏": [
		"瀏",
		"浏",
		"刘"
	],
	"吁": [
		"籲",
		"吁"
	],
	"囉": [
		"囉",
		"啰",
		"罗",
		"羅"
	],
	"啰": [
		"囉",
		"啰",
		"罗",
		"羅"
	],
	"修": [
		"修",
		"俢"
	],
	"犟": [
		"犟",
		"強",
		"强"
	],
	"嗬": [
		"嗬",
		"呵"
	],
	"唿": [
		"唿",
		"呼"
	],
	"媮": [
		"媮",
		"偷"
	],
	"采": [
		"采",
		"採"
	],
	"彩": [
		"彩",
		"采"
	],
	"𠩺": [
		"𠩺",
		"釐"
	],
	"恶": [
		"恶",
		"噁",
		"惡"
	],
	"炰": [
		"炰",
		"炮"
	],
	"辟": [
		"辟",
		"闢",
		"避"
	],
	"避": [
		"辟",
		"避"
	],
	"闢": [
		"辟",
		"闢"
	],
	"滷": [
		"魯",
		"鲁"
	],
	"鹵": [
		"魯",
		"鲁"
	],
	"卤": [
		"魯",
		"鲁"
	],
	"儓": [
		"儓",
		"檯"
	],
	"檯": [
		"儓",
		"檯"
	],
	"台": [
		"台",
		"檯",
		"臺",
		"颱"
	],
	"練": [
		"炼"
	],
	"练": [
		"炼"
	],
	"繫": [
		"系"
	],
	"繋": [
		"系"
	],
	"糓": [
		"谷"
	],
	"穀": [
		"谷"
	],
	"版": [
		"板"
	],
	"祕": [
		"密"
	],
	"秘": [
		"密"
	],
	"汇": [
		"彙",
		"匯"
	],
	"倂": [
		"倂",
		"併"
	],
	"併": [
		"倂",
		"併"
	],
	"幷": [
		"并"
	],
	"并": [
		"幷"
	],
	"矇": [
		"蒙"
	],
	"濛": [
		"蒙"
	],
	"懞": [
		"蒙"
	],
	"煇": [
		"煇",
		"辉",
		"𪸩"
	],
	"𪸩": [
		"煇",
		"辉",
		"𪸩"
	],
	"签": [
		"籤",
		"簽"
	]
} as const;

/**
 * 此表內符合以下任意值時會觸發
 */
export const table_plus_core = {
	"劍": [
		"劍",
		"剑",
		"剣",
		"劎",
		"劒",
		"剱",
		"劔"
	],
	"砲": [
		"砲",
		"炮"
	],
	"偽": [
		"偽",
		"僞"
	],
	"內": [
		"內",
		"内"
	],
	"鬥": [
		"鬭",
		"鬥",
		"闘",
		"鬪"
	],
	"鶏": [
		"鶏",
		"鷄",
		"雞",
		"鸡"
	],
	"兎": [
		"兎",
		"兔"
	],
	"坏": [
		"坯",
		"坏",
		"壊",
		"壞"
	],
	"殻": [
		"殻",
		"殼",
		"壳"
	],
	"像": [
		"像",
		"象"
	],
	"蘇": [
		"苏",
		"蘇",
		"囌"
	],
	"館": [
		"館",
		"館",
		"舘",
		"馆"
	],
	"鳥": [
		"鳥",
		"鸟",
		"𫠓"
	],
	"視": [
		"視",
		"視",
		"视",
		"眎"
	],
	"険": [
		"険",
		"險",
		"险",
		"嶮",
		"崄"
	],
	"絶": [
		"絶",
		"絕",
		"绝"
	],
	"鉄": [
		"鉄",
		"鐵",
		"铁",
		"鐡"
	],
	"諸": [
		"諸",
		"諸",
		"诸"
	],
	"尋": [
		"尋",
		"寻",
		"𡬶"
	],
	"裡": [
		"裡",
		"裏",
		"里"
	],
	"鑑": [
		"鑑",
		"鉴",
		"鑒"
	],
	"麵": [
		"麵",
		"麪",
		"麺"
	],
	"歲": [
		"歲",
		"歳",
		"岁"
	],
	"鐘": [
		"鐘",
		"鍾",
		"钟",
		"锺"
	],
	"會": [
		"會",
		"会",
		"㑹"
	],
	"塗": [
		"塗",
		"凃",
		"涂"
	],
	"髮": [
		"髮",
		"髪"
	],
	"話": [
		"話",
		"话",
		"䛡"
	],
	"閤": [
		"閤",
		"阁"
	],
	"蔘": [
		"蔘",
		"參",
		"参"
	],
	"労": [
		"労",
		"勞",
		"劳"
	],
	"国": [
		"国",
		"囯",
		"國"
	],
	"罵": [
		"罵",
		"骂",
		"駡"
	],
	"対": [
		"対",
		"對",
		"对"
	],
	"鏽": [
		"鏽",
		"銹",
		"锈"
	],
	"駄": [
		"駄",
		"䭾",
		"馱",
		"驮"
	],
	"薩": [
		"薩",
		"萨",
		"蕯"
	],
	"単": [
		"単",
		"單",
		"单"
	],
	"継": [
		"継",
		"繼",
		"继"
	],
	"驗": [
		"驗",
		"验",
		"騐",
		"験"
	],
	"歴": [
		"歴",
		"歷"
	],
	"暦": [
		"暦",
		"曆"
	],
	"団": [
		"团",
		"団",
		"團"
	],
	"麼": [
		"麼",
		"麽",
		"庅"
	],
	"戰": [
		"戦",
		"戰",
		"战"
	],
	"乡": [
		"郷",
		"鄕",
		"鄉",
		"鄊",
		"乡"
	],
	"勉": [
		"勉",
		"勉"
	],
	"餘": [
		"餘",
		"馀",
		"余"
	],
	"網": [
		"網",
		"䋄",
		"䋞",
		"网"
	],
	"託": [
		"託",
		"讬",
		"托"
	],
	"纖": [
		"纖",
		"纤",
		"縴",
		"繊"
	],
	"鍊": [
		"鍊",
		"錬",
		"𫔀",
		"炼",
		"煉"
	],
	"擊": [
		"撃",
		"擊",
		"击"
	],
	"實": [
		"實",
		"実",
		"实",
		"寔"
	],
	"於": [
		"於",
		"扵"
	],
	"證": [
		"證",
		"証",
		"证"
	],
	"據": [
		"據",
		"据",
		"拠"
	],
	"處": [
		"處",
		"处",
		"䖏",
		"処"
	],
	"瞪": [
		"瞪",
		"瞠",
		"眙"
	],
	"肢": [
		"肢",
		"胑"
	],
	"肉": [
		"肉",
		"宍",
		"𠕎"
	],
	"憂": [
		"憂",
		"𢝊",
		"𢚧",
		"𢟜",
		"懮",
		"𨗫"
	],
	"繫": [
		"繫",
		"繋"
	],
	"廻": [
		"廻",
		"迴"
	],
	"録": [
		"録",
		"錄",
		"录"
	],
	"鎗": [
		"鎗",
		"槍",
		"枪"
	],
	"悠": [
		"悠",
		"滺"
	],
	"壶": [
		"壶",
		"壺",
		"壷"
	],
	"茲": [
		"茲",
		"兹",
		"玆"
	],
	"蓋": [
		"蓋",
		"盖",
		"葢"
	],
	"蹟": [
		"蹟",
		"跡",
		"迹"
	],
	"癒": [
		"癒",
		"瘉"
	],
	"辺": [
		"辺",
		"邊",
		"边",
		"邉"
	],
	"準": [
		"準",
		"凖"
	],
	"衛": [
		"衛",
		"衞",
		"卫"
	],
	"晚": [
		"晚",
		"晩"
	],
	"裸": [
		"裸",
		"躶"
	],
	"亀": [
		"亀",
		"龜",
		"龟",
		"𪚧",
		"𪚿",
		"𠃾"
	],
	"凼": [
		"凼",
		"氹"
	],
	"艸": [
		"艸",
		"草"
	],
	"箚": [
		"箚",
		"剳"
	],
	"复": [
		"复",
		"復",
		"複"
	],
	"污": [
		"污",
		"汙",
		"汚"
	],
	"伙": [
		"伙",
		"夥"
	],
	"御": [
		"御",
		"禦"
	],
	"鬱": [
		"鬱",
		"郁"
	],
	"淩": [
		"淩",
		"凌"
	],
	"紮": [
		"紮",
		"扎"
	],
	"痴": [
		"痴",
		"癡"
	],
	"栖": [
		"栖",
		"棲"
	],
	"犇": [
		"犇",
		"奔"
	],
	"範": [
		"範",
		"范"
	],
	"薑": [
		"薑",
		"姜",
		"葁"
	],
	"樸": [
		"樸",
		"朴"
	],
	"諮": [
		"諮",
		"谘",
		"咨"
	],
	"撚": [
		"撚",
		"捻"
	],
	"喂": [
		"喂",
		"餵",
		"餧",
		"𫗭"
	],
	"淨": [
		"淨",
		"凈",
		"净"
	],
	"栗": [
		"栗",
		"慄"
	],
	"挽": [
		"挽",
		"輓"
	],
	"灶": [
		"灶",
		"竈"
	],
	"線": [
		"線",
		"缐",
		"綫",
		"线"
	],
	"盡": [
		"盡",
		"尽",
		"儘"
	],
	"黴": [
		"黴",
		"霉"
	],
	"周": [
		"周",
		"週"
	],
	"並": [
		"並",
		"并",
		"併"
	],
	"讚": [
		"讚",
		"讃"
	],
	"観": [
		"観",
		"觀",
		"观",
		"覌"
	],
	"遊": [
		"遊",
		"游"
	],
	"启": [
		"启",
		"啓",
		"啟",
		"啔"
	],
	"廄": [
		"廄",
		"厩",
		"廐",
		"廏"
	],
	"氣": [
		"気",
		"氣",
		"气"
	],
	"欲": [
		"慾",
		"欲"
	],
	"傑": [
		"傑",
		"杰"
	],
	"鍛": [
		"鍛",
		"锻",
		"煅"
	],
	"徵": [
		"徵",
		"徴"
	],
	"閒": [
		"閒",
		"𫔮",
		"閑",
		"闲"
	],
	"贊": [
		"贊",
		"赞",
		"賛"
	],
	"櫻": [
		"櫻",
		"桜",
		"樱"
	],
	"尨": [
		"尨",
		"狵"
	],
	"圈": [
		"圈",
		"圏"
	],
	"凶": [
		"凶",
		"兇"
	],
	"浜": [
		"浜",
		"濱",
		"滨"
	],
	"煙": [
		"煙",
		"烟",
		"菸"
	],
	"黒": [
		"黒",
		"黑"
	],
	"樂": [
		"樂",
		"乐",
		"楽"
	],
	"薬": [
		"薬",
		"藥",
		"药",
		"葯",
		"𣛙"
	],
	"劵": [
		"劵",
		"券",
		"卷"
	],
	"貳": [
		"貳",
		"贰",
		"弐",
		"貮",
		"𢎐",
		"二"
	],
	"隷": [
		"隷",
		"隸",
		"隶"
	],
	"姫": [
		"姫",
		"姬"
	],
	"燻": [
		"燻",
		"熏"
	],
	"籲": [
		"籲",
		"龥"
	],
	"齧": [
		"齧",
		"啮",
		"𪘂",
		"囓",
		"噛",
		"嚙"
	],
	"鹼": [
		"鹼",
		"碱",
		"硷"
	],
	"咸": [
		"咸",
		"鹹"
	],
	"穗": [
		"穗",
		"穂"
	],
	"廢": [
		"廢",
		"廃",
		"废"
	],
	"蹠": [
		"蹠",
		"跖"
	],
	"吒": [
		"吒",
		"咤"
	],
	"剷": [
		"剷",
		"鏟",
		"铲",
		"刬",
		"剗"
	],
	"擗": [
		"擗",
		"劈"
	],
	"核": [
		"核",
		"覈"
	],
	"脣": [
		"脣",
		"唇",
		"唇"
	],
	"升": [
		"升",
		"昇"
	],
	"磐": [
		"磐",
		"盤",
		"盘"
	],
	"溪": [
		"溪",
		"渓"
	],
	"谿": [
		"谿",
		"嵠"
	],
	"折": [
		"折",
		"摺"
	],
	"祐": [
		"祐",
		"佑"
	],
	"瓮": [
		"瓮",
		"罋",
		"甕"
	],
	"蹤": [
		"蹤",
		"踪",
		"踨"
	],
	"暗": [
		"闇",
		"暗"
	],
	"昵": [
		"昵",
		"暱"
	],
	"布": [
		"布",
		"佈"
	],
	"為": [
		"為",
		"为",
		"爲"
	],
	"綳": [
		"綳",
		"繃",
		"绷"
	],
	"痺": [
		"痺",
		"痹"
	],
	"痲": [
		"痲",
		"痳"
	],
	"雇": [
		"雇",
		"僱"
	],
	"敘": [
		"敘",
		"叙",
		"敍"
	],
	"盪": [
		"盪",
		"蕩",
		"荡"
	],
	"勛": [
		"勛",
		"勳",
		"勋",
		"勲"
	],
	"祕": [
		"祕",
		"秘"
	],
	"牆": [
		"牆",
		"墙",
		"墻"
	],
	"爾": [
		"爾",
		"尔",
		"尓"
	],
	"焰": [
		"焰",
		"焔"
	],
	"默": [
		"默",
		"黙"
	],
	"壓": [
		"壓",
		"压",
		"圧"
	],
	"廸": [
		"廸",
		"迪"
	],
	"曉": [
		"曉",
		"晓",
		"暁"
	],
	"霸": [
		"霸",
		"覇"
	],
	"霊": [
		"霊",
		"靈",
		"灵"
	],
	"泪": [
		"泪",
		"涙",
		"淚"
	],
	"牺": [
		"牺",
		"犠",
		"犧"
	],
	"藉": [
		"藉",
		"耤"
	],
	"噸": [
		"噸",
		"吨"
	],
	"俱": [
		"俱",
		"倶"
	],
	"粽": [
		"粽",
		"糉",
		"糭"
	],
	"向": [
		"向",
		"曏",
		"嚮"
	],
	"悽": [
		"悽",
		"淒",
		"凄"
	],
	"鱷": [
		"鱷",
		"鰐",
		"鳄"
	],
	"滷": [
		"滷",
		"鹵",
		"卤"
	],
	"颜": [
		"颜",
		"顏",
		"顔"
	],
	"衝": [
		"衝",
		"沖",
		"冲"
	],
	"樑": [
		"樑",
		"梁"
	],
	"砂": [
		"砂",
		"沙"
	],
	"炭": [
		"炭",
		"碳"
	],
	"糸": [
		"糸",
		"絲",
		"丝"
	],
	"簷": [
		"簷",
		"檐"
	],
	"涌": [
		"涌",
		"湧"
	],
	"糓": [
		"穀",
		"糓"
	],
	"両": [
		"両",
		"两",
		"兩"
	],
	"家": [
		"家",
		"傢"
	],
	"妳": [
		"妳",
		"你"
	],
	"她": [
		"她",
		"他"
	],
	"藤": [
		"藤",
		"籐",
		"籘"
	],
	"嬉": [
		"嬉",
		"嘻"
	],
	"亘": [
		"亘",
		"亙"
	],
	"恆": [
		"恆",
		"恒"
	],
	"鶇": [
		"鶇",
		"鶫",
		"鸫"
	],
	"姉": [
		"姉",
		"姐",
		"姊"
	],
	"剁": [
		"剁",
		"刴"
	],
	"泄": [
		"泄",
		"洩"
	],
	"舖": [
		"舖",
		"铺",
		"鋪",
		"舗"
	],
	"效": [
		"效",
		"効"
	],
	"喻": [
		"喻",
		"喩"
	],
	"插": [
		"挿",
		"插",
		"揷"
	],
	"銳": [
		"銳",
		"鋭",
		"锐"
	],
	"權": [
		"權",
		"権",
		"权"
	],
	"経": [
		"経",
		"經",
		"经"
	],
	"歓": [
		"歓",
		"歡",
		"欢"
	],
	"孃": [
		"嬢",
		"孃"
	],
	"済": [
		"済",
		"濟",
		"济"
	],
	"收": [
		"収",
		"收"
	],
	"綠": [
		"綠",
		"緑",
		"绿"
	],
	"唖": [
		"唖",
		"啞",
		"哑"
	],
	"剿": [
		"剿",
		"勦",
		"𠞰"
	],
	"禍": [
		"禍",
		"禍",
		"祸"
	],
	"営": [
		"營",
		"営",
		"营"
	],
	"産": [
		"產",
		"産",
		"产"
	],
	"査": [
		"查",
		"査"
	],
	"絵": [
		"繪",
		"絵",
		"绘"
	],
	"懐": [
		"懷",
		"懐",
		"怀"
	],
	"釈": [
		"釋",
		"釈",
		"释"
	],
	"蔵": [
		"藏",
		"蔵"
	],
	"娯": [
		"娛",
		"娯",
		"娱"
	],
	"焼": [
		"燒",
		"焼",
		"烧"
	],
	"拡": [
		"擴",
		"拡",
		"扩"
	],
	"賎": [
		"賤",
		"賎",
		"贱"
	],
	"銭": [
		"錢",
		"銭",
		"钱"
	],
	"雑": [
		"雜",
		"雑",
		"杂"
	],
	"聴": [
		"聽",
		"聴",
		"听"
	],
	"帯": [
		"帶",
		"帯",
		"带"
	],
	"閲": [
		"閲",
		"阅"
	],
	"覧": [
		"覽",
		"覧",
		"览"
	],
	"悪": [
		"惡",
		"悪",
		"恶"
	],
	"亜": [
		"亞",
		"亜",
		"亚"
	],
	"氷": [
		"冰",
		"氷"
	],
	"县": [
		"県",
		"縣",
		"县"
	],
	"肅": [
		"粛",
		"肅",
		"肃"
	],
	"専": [
		"專",
		"専",
		"专"
	],
	"様": [
		"樣",
		"様",
		"样"
	],
	"関": [
		"關",
		"関",
		"关"
	],
	"檢": [
		"検",
		"檢",
		"检"
	],
	"侮": [
		"侮",
		"侮"
	],
	"沉": [
		"沉",
		"沈"
	],
	"嚐": [
		"嚐",
		"嘗",
		"尝"
	],
	"搾": [
		"搾",
		"榨"
	],
	"获": [
		"获",
		"獲",
		"穫"
	],
	"繮": [
		"繮",
		"缰",
		"韁"
	],
	"贋": [
		"贋",
		"贗",
		"赝"
	],
	"獃": [
		"呆"
	],
	"杯": [
		"杯",
		"盃"
	],
	"呪": [
		"呪",
		"咒",
		"詋"
	],
	"䇳": [
		"䇳",
		"笺",
		"箋",
		"牋"
	],
	"竝": [
		"竝",
		"𠀤"
	],
	"彷": [
		"彷",
		"徬"
	],
	"贑": [
		"贑",
		"𫎬"
	],
	"崖": [
		"崖",
		"崕",
		"厓"
	],
	"岩": [
		"岩",
		"巖",
		"巗",
		"巌"
	],
	"灕": [
		"灕",
		"漓"
	],
	"粘": [
		"粘",
		"黏"
	],
	"娴": [
		"娴",
		"嫺",
		"嫻"
	],
	"哗": [
		"哗",
		"嘩",
		"譁"
	],
	"拔": [
		"拔",
		"抜"
	],
	"湿": [
		"湿",
		"溼",
		"濕"
	],
	"稻": [
		"稻",
		"稲"
	],
	"楕": [
		"楕",
		"椭",
		"橢"
	],
	"毎": [
		"毎",
		"每"
	],
	"篦": [
		"篦",
		"箆"
	],
	"騨": [
		"騨",
		"驒",
		"驔"
	],
	"猛": [
		"猛",
		"勐"
	],
	"吿": [
		"吿",
		"告"
	],
	"刃": [
		"刃",
		"刄"
	],
	"教": [
		"教",
		"敎"
	],
	"蛎": [
		"蛎",
		"蜊",
		"蠣"
	]
} as const;

