export const data = [
    {
        id: 1,
        title: "[all the time I pray to Buddha]",
        content: "all the time I pray to Buddha I keep on killing mosquitoes",
        start: 0,
        stop: 0,
        interval: 0
    },
    {
        id: 2,
        title: "[even with insects]",
        content: "even with insects— some can sing, some can’t",
        start: 0,
        stop: 0,
        interval: 0
    },
    {
        id: 3,
        title: "[goes out comes back]",
        content: "goes out comes back— the love life of a cat",
         start: 0,
        stop: 0,
        interval: 0
    },
    {
        id: 4,
        title: "[mosquito at my ear]",
        content: "mosquito~~ at my ear—- does he think I am deaf ?",
         start: 0,
        stop: 0,
        interval: 0
    },
    {
        id: 5,
        title: "[on a branch ...]",
        content: "on a branch floating downriver a cricket singing ...",
         start: 0,
        stop: 0,
        interval: 0
    },
    {
        id: 6,
        title: "[the snow is melting]",
        content: "the snow is melting and the village is flooded with children",
         start: 0,
        stop: 0,
        interval: 0
    },
    {
        id: 7,
        title: "[under the evening moon]",
        content: "under the evening moon the snail is stripped to the waist",
         start: 0,
        stop: 0,
        interval: 0
    },
    {
        id: 8,
        title: "[a huge frog and i]",
        content: "a huge frog and I staring at each other neither of us moves",
         start: 0,
        stop: 0
    },
    {
        id: 9,
        title: "[writing shit about new snow]",
        content: "writing shit about new snow for the rich is not art",
         start: 0,
        stop: 0,
        interval: 0
    },
    {
        id: 10,
        title: "[the crow]",
        content: "the crow walks along there as if it were tilling the field",
         start: 0,
        stop: 0,
        interval: 0
    },
    {
        id: 11,
        title: "[a cuckoo sings]",
        content: "a cuckoo sings to me to the mountain to me to the mountain",
         start: 0,
        stop: 0,
        interval: 0
    },
    {
        id: 12,
        title: "[how much]",
        content: "how much are you enjoying yourself, tiger moth ?",
        start: 0,
        stop: 0,
        interval: 0
    },
    {
        id: 13,
        title: "[the toad! it looks like]",
        content: "the toad! it looks like it could belch a cloud !",
         start: 0,
        stop: 0,
        interval: 0
    },
    {
        id: 14,
        title: "[that pretty girl]",
        content: "that pretty girl-- munching and rustling the wrapped-up ricecake",
         start: 0,
        stop: 0,
        interval: 0
    },
    {
        id: 15,
        title: "[seen]",
        content: "through a telescope : ten cents worth of fog",
         start: 0,
        stop: 0,
        interval: 0
    },
    {
        id: 16,
        title: "[the man pulling radishes]",
        content: "the man pulling radishes pointed my way with a radish",
        start: 0,
        stop: 0,
        interval: 0
    },
    {
        id: 17,
        title: "[in these latter-day]",
        content: "in these latter-day degenerate times cherry blossoms everywhere !",
        start: 0,
        stop: 0,
        interval: 0
    },
];